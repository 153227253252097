<script setup lang="ts">
import Icon from '~/components/global/Icon.vue'
import Container from '~/components/layout/Container.vue'
import useQuickOfferModalStore from '~/stores/modals/useQuickOfferModal'

const quickOfferModalStore = useQuickOfferModalStore()
</script>

<template>
  <div class="c-tailored-offer-banner">
    <Container class="c-tailored-offer-banner__container">
      <div class="c-tailored-offer-banner__content">
        <div class="c-tailored-offer-banner__bg">
          <NuxtImg
            src="/images/components/services-banner/bg.jpg"
            loading="lazy"
            width="1320"
            height="816"
            densities="1x"
          />
        </div>

        <h2 class="c-tailored-offer-banner__title">
          {{ $t('pages.estateListing.header.banner.title') }}
        </h2>

        <div class="c-tailored-offer-banner__text">
          <strong>{{ $t('pages.estateListing.header.banner.text.0') }}</strong>

          {{ $t('pages.estateListing.header.banner.text.1') }}
        </div>

        <div class="c-tailored-offer-banner__button">
          <IButton
            class="has-icon"
            color="secondary"
            @click="quickOfferModalStore.open()"
          >
            {{ $t('pages.estateListing.header.banner.buttonText') }}

            <Icon name="arrow-right-thick" />
          </IButton>
        </div>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-tailored-offer-banner {
  margin-bottom: 100px;

  @include breakpoint-down('md') {
    margin-bottom: 80px;
  }

  &__content {
    padding: 100px 120px;
    position: relative;
    display: grid;
    grid-template:
      'title button' auto
      'list button' auto
      / 1fr auto;
    gap: 20px 24px;

    @include breakpoint-down('xl') {
      padding: 60px 80px;
    }

    @include breakpoint-down('md') {
      padding: 30px;
      gap: 0;
      grid-template:
        'title' auto
        'list' auto
        'button' auto
        / 1fr;
    }
  }

  &__title {
    grid-area: title;
    font-size: 40px;
    line-height: 1;
    color: var(--color-light);
    margin: 0;
    max-width: 600px;

    @include breakpoint-down('md') {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }

  &__text {
    grid-area: list;
    color: var(--color-light);
    max-width: 500px;
    line-height: 1.25;

    @include breakpoint-down('md') {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 24px;
    }
  }

  &__button {
    grid-area: button;
    align-self: center;

    :deep(.button) {
      --button--font-size: 18px;
      --button--padding-left: 36px;
      --button--padding-right: 36px;

      min-height: 64px;
      font-weight: 500;

      @include breakpoint-down('lg') {
        --button--font-size: 16px;
        --button--padding-left: 24px;
        --button--padding-right: 24px;
        min-height: 54px;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--color-primary);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        hsla(
            var(--color-primary-h),
            var(--color-primary-s),
            var(--color-primary-l),
            1
          )
          20%,
        hsla(
          var(--color-primary-h),
          var(--color-primary-s),
          var(--color-primary-l),
          0
        )
      );
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
